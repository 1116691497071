<template>
  <div id="review-order">
    <div class="container-fluid">
        <div class="row" v-if="$store.getters.cartPreorders.length > 0">
          <div class="col-12">
            <div class="message p-3 text-center font-weight-bold mb-3">
              <p>Items in your cart are on pre-order and shipping may be delayed:<p>
              <div class="text-center mb-1 red" v-for="preorderItem in $store.getters.cartPreorders" v-bind:key="preorderItem.ID+preorderItem.type">{{ preorderItem.post_title+' ('+preorderItem.type+')' }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8">
            <section id="address-review" class="mb-3">
              <div class="box-header mb-0">Shipping Address</div>
              <div class="content p-4">
                <div class="row">
                  <div class="col-6" v-if="$store.getters.orderShippingAddress != null">
                    <h5>Shipping Address</h5>
                    <p>{{ $store.getters.orderShippingAddress.company }}<br>
                    {{ $store.getters.orderShippingAddress.name }}<br>
                    {{ $store.getters.orderShippingAddress.address1 }}<br>
                    {{ $store.getters.orderShippingAddress.city }}, {{ $store.getters.orderShippingAddress.state }} {{ $store.getters.orderShippingAddress.zipcode }}
                    </p>
                    <a class="edit-link" @click="$store.commit('changeOrderStep', 'shipping')">Edit</a>
                  </div>
                  <div class="col-6" v-if="typeof $store.state.orderInfo.billingAddress != 'undefined' && $store.state.orderInfo.billingAddress != null">
                    <h5>Billing Address</h5>
                    <p>{{ $store.getters.orderBillingAddress.company }}<br>
                    {{ $store.getters.orderBillingAddress.name }}<br>
                    {{ $store.getters.orderBillingAddress.address1 }}<br>
                    {{ $store.getters.orderBillingAddress.city }}, {{ $store.getters.orderBillingAddress.state }} {{ $store.getters.orderBillingAddress.zipcode }}
                    </p>
                    <a class="edit-link" @click="$store.commit('changeOrderStep', 'billing')">Edit</a>
                  </div>
                </div>
              </div>
            </section>
            <section id="shipping-review" class="mb-3">
              <div class="box-header mb-0">Shipping Method</div>
              <div class="content p-4">
                <h5>{{ $store.state.orderInfo.shippingMethod.name }}</h5>
                <p v-if="$store.state.orderInfo.shippingMethod.time != null">{{ $store.state.orderInfo.shippingMethod.time }} days</p>
                <a class="edit-link" @click="$store.commit('changeOrderStep', 'shipping')">Edit</a>
              </div>
            </section>
            <section id="shipping-review" class="mb-3">
              <div class="box-header mb-0">Payment Method</div>
              <div class="content p-4">
                <h5>{{ $store.state.orderInfo.paymentMethod }}</h5>
                <p v-if="$store.state.orderInfo.ccInfo.number != ''">XXXXXXXXXXXX{{ $store.state.orderInfo.ccInfo.number.substr($store.state.orderInfo.ccInfo.number.length - 4) }}</p>
                <a class="edit-link" @click="$store.commit('changeOrderStep', 'billing')">Edit</a>
              </div>
            </section>
          </div>
          <div class="col-12 col-lg-4">
            <Cart></Cart>
            <section id="place-order" class="p-3 border-box" v-if="$store.state.userData.blocked == '_blank_'">
              <div class="row d-flex align-items-center mb-3">
                <div class="col-12">
                  <label>PO Number (if applicable)</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-12">
                  <input class="w-100 w-full mb-3" type="text" v-model="poNumber" />
                </div>
              </div>
              <div class="row d-flex align-items-center">
                <div class="col-12">
                  <a class="checkout button w-100 mb-3" @click="submitOrder" v-if="$store.getters.isOrderComplete && submitting != true">Submit Order</a>
                </div>
                <div class="col-12 text-0">
                  Your order will process and you will be charged. Finalize your order before proceeding. Do not navigate away from this page until your submission is complete.
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Cart from '@/components/fermentation/Cart.vue'
import Api from '@/models/Api.js'
import GlobalMethods from '@/components/fermentation/GlobalMethods.js'

export default {
  name: 'ReviewOrder',
  components: {
    Cart
  },
  data() {
    return {
      paymentComplete: false,
      submitting: false
    };
  },
  computed: {
    poNumber: {
      get() {
        return this.$store.state.orderInfo.poNumber;
      },
      set(value) {
        this.$store.commit('setPoNumber', value);
      }
    }
  },
  methods: {
    ...GlobalMethods,
    submitOrder() {
      if (this.submitting == true) {
        return false;
      }
      this.$overlay(true, '<div>Wait one moment while we process your order. Do not navigate away from this page until your submission is complete or your order may not be correctly processed.</div>');

      this.logOrder();
      this.submitting = true;

      if (this.$store.state.orderInfo.paymentMethod == false) {
        this.$store.state.orderInfo.paymentMethod = 'Credit Card';
      }

      if (this.$store.state.orderInfo.paymentMethod == 'Credit Card') {
        this.pay();
      } else if (this.$store.state.orderInfo.paymentMethod == 'ACH') {
        this.achPay();
      } else {
        this.saveOrder();
      }
    },
    saveOrder(paymentResponse=null) {
      let order = {
        customerId: this.$store.state.userData.id,
        ...this.$store.state.orderInfo,
        pallets: this.$store.getters.pallets,
        orderItems: this.$store.state.cartItems,
        totalPrice: this.$store.getters.cartFinalTotal,
        paymentResponse: paymentResponse,
        orderUrl: this.$store.state.orderUrl,
        discountAmount: 0.00
      };
      if (this.$store.state.orderInfo.discounts.length > 0) {
        order['discountAmount'] = this.$store.getters.discountAmount(this.$store.state.orderInfo.discounts[0]);
      }
      Api.postAuth('order', order, this.$store.state.userData.token).then(response => {
        console.log(response);
        if (response.success === false) {
          this.$overlay(true, '<div class="text-1">('+response.message+') Could not create your order. To complete your order, please contact the fermentation team at 503-485-9010 or fermentation@oregonfruit.com. Do not resubmit your order.</div>', null);
        } else {
          this.$store.commit('changeOrderStep', 'confirmation');
          this.$store.commit('resetOrder');
          this.$overlay(false);
          this.submitting = false;
          this.fireOrderCompleteEvent(order);
          this.$store.commit('trackHubspotEvent', { id: 'Order Placed', value: order.totalPrice });
          this.microsoftPurchaseConversion(order);
        }
      });
    },
    microsoftPurchaseConversion(order) {
      window.uetq = window.uetq || [];

      let items = [];
      order.orderItems.forEach(item => {
        items.push(item.nav_id);
      });

      window.uetq.push("event", "PRODUCT_PURCHASE", {
        "ecomm_prodid": items, // Pass an array of product IDs here
        "ecomm_pagetype": "PURCHASE",
        "revenue_value": order.totalPrice,
        "currency": "USD"
      });
    },
    fireOrderCompleteEvent(order) {
      window.dataLayer = window.dataLayer || [];

      let gaItems = [];
      order.orderItems.forEach(item => {
        if (item.post_type != 'puree' && item.post_type != 'concentrate') {
          return;
        }
        gaItems.push({
          item_id: item.nav_id,
          item_name: item.post_title,
          quantity: item.qty,
          price: item.prices[item.type],
          item_category: item.post_type,
          item_variant: item.type
        });
      });

      const date = new Date();
      const components = [
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      ];
      
      let transaction_id = components.join("");

      let customerType = 'existing';

      if (this.$store.state.previousOrders.length == 0 || this.$store.state.userData.id == 1) {
        customerType = 'new';
        // window.dataLayer.push({
        //   event: 'new_customer_purchase',
        //   totalPrice: order.totalPrice,
        //   transaction_id: transaction_id
        // });
      } else {
        // window.dataLayer.push({
        //   event: 'existing_customer_purchase',
        //   totalPrice: order.totalPrice,
        //   transaction_id: transaction_id
        // });
      }
      
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currency: 'USD',
          value: order.totalPrice,
          items: gaItems,
          transaction_id: transaction_id,
          customer_type: customerType
        },
        customer_type: customerType
      });
    },
    logOrder() {
      let orderInfoFiltered = JSON.parse(JSON.stringify(this.$store.state.orderInfo));
      orderInfoFiltered['ccInfo'] = 'REDACTED';
      let order = {
        ...orderInfoFiltered,
        ...this.$store.state.userData,
        pallets: this.$store.getters.pallets,
        orderItems: this.$store.state.cartItems,
        totalPrice: this.$store.getters.cartFinalTotal,
        discountAmount: 0.00
      };
      if (this.$store.state.orderInfo.discounts.length > 0) {
        order['discountAmount'] = this.$store.getters.discountAmount(this.$store.state.orderInfo.discounts[0]);
      }
      Api.postAuth('log-order', order, this.$store.state.userData.token).then(response => {
        return response;
      });
    },
    getPaymentToken() {
      let tokenRequest = {
        ssl_first_name: this.$store.getters.orderBillingAddress.name.split(" ")[0],
        ssl_last_name: this.$store.getters.orderBillingAddress.name.split(" ")[1],
        ssl_amount: this.$store.getters.cartFinalTotal
      };
      Api.postAuth("payment/token", tokenRequest, this.$store.state.userData.token).then(response => {
        this.paymentToken = response;
      });
    },
    getAchPaymentToken() {
      let tokenRequest = {
        ssl_first_name: this.$store.getters.orderBillingAddress.name.split(" ")[0],
        ssl_last_name: this.$store.getters.orderBillingAddress.name.split(" ")[1],
        ssl_amount: this.$store.getters.cartFinalTotal
      };
      Api.postAuth("payment/ach-token", tokenRequest, this.$store.state.userData.token).then(response => {
        this.paymentToken = response;
      });
    },
    pay() {
      var paymentData = {
        ssl_txn_auth_token: this.paymentToken,
        ssl_card_number: this.$store.state.orderInfo.ccInfo.number, //'4000000000000002',
        ssl_exp_date: this.$store.state.orderInfo.ccInfo.month+'/'+this.$store.state.orderInfo.ccInfo.year.substr(-2), // '08/22',
        ssl_get_token: 'y',
        ssl_add_token: 'y',
        ssl_first_name: this.$store.getters.orderBillingAddress.name.split(" ")[0],
        ssl_last_name: this.$store.getters.orderBillingAddress.name.split(" ")[1],
        ssl_cvv2cvc2: this.$store.state.orderInfo.ccInfo.cvv,
        ssl_merchant_txn_id: '789544',
        ssl_avs_address: this.$store.getters.orderBillingAddress.address1,
        ssl_avs_zip: this.$store.getters.orderBillingAddress.zipcode
      };
      var callback = {
        onError: error => {
          console.log(error);
          // this.$overlay(true, error, 3000);
          Api.postAuth('log-js-error', { errors: error }, this.$store.state.userData.token).then(() => {
            this.$overlay(true, 'There was an error processing your order. Please try again.', 3000);
            this.$store.commit('changeOrderStep', 'billing');
            this.submitting = false;
          });
        },
        onDeclined: response => {
          console.log("Result Message=" + response['ssl_result_message']);
          console.log(JSON.stringify(response));
          this.$overlay(true, "Your payment was declined. "+(typeof response['ssl_result_message'] != 'undefined' ? response['ssl_result_message'] : ''), 3000);
          this.$store.commit('changeOrderStep', 'billing');
          this.submitting = false;
        },
        onApproval: (response) => {
          this.saveOrder(response['ssl_approval_code']);
        }
      };
      return ConvergeEmbeddedPayment.pay(paymentData, callback); // eslint-disable-line
    },
    achPay() {
      var paymentData = {
        ssl_txn_auth_token: this.paymentToken,
        ssl_first_name: this.$store.getters.orderBillingAddress.name.split(" ")[0],
        ssl_last_name: this.$store.getters.orderBillingAddress.name.split(" ")[1],
        ssl_company: this.$store.getters.orderBillingAddress.company,
        ssl_avs_address: this.$store.getters.orderBillingAddress.address1,
        ssl_avs_zip: this.$store.getters.orderBillingAddress.zipcode,
        ssl_aba_number: this.$store.state.orderInfo.achInfo.routingNumber,
        ssl_bank_account_number: this.$store.state.orderInfo.achInfo.accountNumber,
        ssl_bank_account_type: 1,
        ssl_agree: 1,
        ssl_amount: this.$store.getters.cartFinalTotal,
      };
      var callback = {
        onError: error => {
          console.log(error);
          // this.$overlay(true, error, 3000);
          Api.postAuth('log-js-error', { errors: error }, this.$store.state.userData.token).then(() => {
            this.$overlay(true, 'There was an error processing your order. Please try again.', 3000);
            this.$store.commit('changeOrderStep', 'billing');
            this.submitting = false;
          });
        },
        onDeclined: response => {
          console.log("Result Message=" + response['ssl_result_message']);
          console.log(JSON.stringify(response));
          this.$overlay(true, "Your payment was declined. "+(typeof response['ssl_result_message'] != 'undefined' ? response['ssl_result_message'] : ''), 3000);
          this.$store.commit('changeOrderStep', 'billing');
          this.submitting = false;
        },
        onApproval: (response) => {
          this.saveOrder(response['ssl_approval_code']);
        }
      };
      return ConvergeEmbeddedPayment.pay(paymentData, callback); // eslint-disable-line
    }
  },
  beforeMount() {
    this.checkOrderProgress();
  },
  mounted() {
    let convergeApiUrl = "https://api.convergepay.com/hosted-payments/Checkout.js";
    this.$loadScript(convergeApiUrl) // https://api.demo.convergepay.com/hosted-payments/Checkout.js
    .then(() => {
      if (this.$store.state.orderInfo.paymentMethod == 'Credit Card') {
        this.getPaymentToken();
      }
      if (this.$store.state.orderInfo.paymentMethod == 'ACH') {
        this.getAchPaymentToken();
      }
    });
  }
}
</script>

<style lang="scss" scoped>

</style>